import React, { useState } from "react"
import firebase from 'firebase/app';
import 'firebase/auth';
import "../layout.css"

const Login = ({ pageContext }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onSubmitLogin = async (e) => {
    e.preventDefault();
    await firebase.auth().signInWithEmailAndPassword(email, password).then(
      (result) => {},
    ).catch((error) => alert(error.message));
  };

  const setEmailFromInput = (e) => {
    setEmail(e.target.value);
  }

  const setPasswordFromInput = (e) => {
    setPassword(e.target.value);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h5 className="card-title text-center">Sign In</h5>
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={setEmailFromInput} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={setPasswordFromInput} />
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={onSubmitLogin}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Login.propTypes = {
}

export default Login
