import React from 'react';

const Ingredient = ({ nameOfIngredient, index, onIngredientUpdate}) => {
    const onIngredientChange = (e) => {
        onIngredientUpdate(index, e.target.value);
    }
    return (
        <div className="Ingredient">
            <input type="text" className="form-control mb-2" placeholder="Example: Oil" value={nameOfIngredient} onChange={onIngredientChange} />
        </div>
    );
};

export default Ingredient;