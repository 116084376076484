import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { assocPath, pathOr } from 'ramda';
import { useImmer } from 'use-immer';
import RecipeStep from '../../components/RecipeStep';
import IngredientsGroup from '../../components/IngredientsGroup';
import FileUpload from '../../components/FileUpload';
import { remove } from 'ramda';
import { getNewRecipeInstance } from '../../models/Recipe.class';

const AddRecipePage = ({ userData }) => {
    const [recipe, setRecipe] = useImmer(getNewRecipeInstance(userData.id));

    const onAddRecipeStep = (e) => {
        e.preventDefault();
        setRecipe(draft => {
            draft.steps.push({ title: "", image: "", isEditMode: true });
        });
    };

    const onConfirmStep = (index, editMode) => {
        setRecipe(draft => {
            draft.steps[index].isEditMode = editMode;
        });
    };

    const onDeleteStep = (index) => {
        const currentSteps = pathOr([], ['steps'], recipe);
        const steps = remove(index, 1, currentSteps);
        setRecipe(draft => {
            draft.steps = steps;
        });
    }

    const createNewRecipe = async (e) => {
        e.preventDefault();
        var shouldCreate = window.confirm("Are you sure to create a new Recipe?");
        if (shouldCreate) {
            try {
                await firebase.firestore().collection('recipes').add(recipe);
                alert('New Recipe created in database!');
            } catch (error) {
                console.log('Error creating a new recipie', error);
            }
        } else {
            return false;
        } 
    }

    const onAddIngredientsGroup = (e) => {
        e.preventDefault();
        setRecipe(draft => {
            draft.ingredients.push({ items: [], purpose: '', isEditMode: true });
        });
    }

    const onIngredientGroupChange = (groupIndex, data) => {
        setRecipe(draft => {
            draft.ingredients[groupIndex] = data;
        });
    }


    const onIngredientGroupDelete = (groupIndex) => {
        setRecipe(draft => {
            draft.ingredients = remove(groupIndex, 1, draft.ingredients);
            return draft;
        });
    }

    const setIngredientGroupPurpose = (groupIndex, purposeText) => {
        setRecipe(draft => {
            draft.ingredients[groupIndex].purpose = purposeText;
            return draft;
        });
    }

    const setIsEditModeForIngredientGroup = (groupIndex, isEditMode) => {
        setRecipe(draft => {
            draft.ingredients[groupIndex].isEditMode = isEditMode;
            return draft;
        });
    }

    const setGroupIngredient = (groupIndex, ingredientIndex, ingredientName) => {
        setRecipe(draft => {
            const updatedGroups = assocPath([groupIndex, 'items', ingredientIndex], ingredientName, draft.ingredients);
            draft.ingredients = updatedGroups;
            return draft;
        })
    }

    const onIngredientAdd = (groupIndex, ingredientName) => {
        setRecipe(draft => {
            draft.ingredients[groupIndex].items.push(ingredientName);
        })
    }

    const onSetRecipeData = (e) => {
        e.preventDefault();
        const inputName = e.target.name;
        const inputValue = pathOr('', ['target', 'value'], e);
        setRecipe(draft => {
            draft[inputName] = inputValue.trim();
        });
    }

    const onStepTextChange = (index, newText) => {
        setRecipe(draft => {
            draft.steps[index].title = newText;
        });
    }

    const onStepImageChange = (index, imageUrl) => {
        setRecipe(draft => {
            draft.steps[index].image = imageUrl;
        });
    }

    const onRecipeMainImageUploadComplete = (imageUrls) => {
        const [mainImageUrl] = imageUrls;
        setRecipe(draft => {
            draft.mainImageUrl = mainImageUrl;
        });
    }

    return (
        <div className="Row mt-3">
            <div className="col">
                <form>
                    <div className="form-group">
                        <h6 htmlFor="title">Title:</h6>
                        <input type="text" className="form-control" id="title" placeholder="Example: Tasty Chicken Recipe" onChange={onSetRecipeData} name="title" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="description">Description:</h6>
                        <input type="text" className="form-control" id="description" placeholder="Example: A delicious italian inspired recipe which is not only easy to cook but to prepare aswell with healthy ingredients." onChange={onSetRecipeData} name="description" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="numberOfservings">Number of Servings:</h6>
                        <input type="text" className="form-control" id="numberOfServings" placeholder="Example: 4 Persons" onChange={onSetRecipeData} name="numberOfServings" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="pageUrl">Page URL:</h6>
                        <input type="text" className="form-control" id="pageUrl" placeholder="Example: delicious-crispy-chicken" onChange={onSetRecipeData} name="pageUrl" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="totalCookingTime">Total Cooking Time:</h6>
                        <input type="text" className="form-control" id="totalCookingTime" placeholder="Example: 1 Hour" onChange={onSetRecipeData} name="totalCookingTime" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="youtubeVideoId">Youtube Video ID:</h6>
                        <input type="text" className="form-control" id="youtubeVideoId" placeholder="Example: ZfxUjSroNFI" onChange={onSetRecipeData} name="youtubeVideoId" />
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="recipe-main-image">Recipe Main Image:</h6>
                        <FileUpload
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            fileUploadPath="recipes-main"
                            onFilesUploadComplete={onRecipeMainImageUploadComplete}
                            uniqId={`recipe-main-image`}
                        />
                        {recipe.mainImageUrl && (
                        <div>
                            <img className="mt-2" src={recipe.mainImageUrl} style={{width: '600px'}} />
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <h6 htmlFor="recipe-steps">Recipe Steps:</h6>
                        {recipe.steps.length === 0 && (
                            <div className="row">
                                <div className="alert col alert-warning" role="alert">
                                    Currently no recipe steps defined
                                </div>
                            </div>
                        )}
                        <div className="row">{
                            recipe.steps.map((recipeStep, index) => {
                                return (
                                    <RecipeStep
                                        data={recipeStep}
                                        index={index}
                                        key={index}
                                        onConfirmStep={onConfirmStep}
                                        onDeleteStep={onDeleteStep}
                                        onStepTextChange={onStepTextChange}
                                        onStepImageChange={onStepImageChange}
                                    />)
                            })
                        }
                        </div>
                        <button className="btn btn-primary" onClick={onAddRecipeStep}>Add Step</button>
                    </div>
                    <hr />
                    <div className="form-group">
                        <h6 htmlFor="recipe-steps">Ingredient Groups:</h6>
                        <div className="row">
                            {recipe.ingredients.length === 0 && (
                                <div className="col alert alert-warning" role="alert">
                                    Currently no recipe ingredients defined
                                </div>
                            )}
                            {
                                recipe.ingredients.map((ingredientGroup, index) => {
                                    return (
                                        <IngredientsGroup
                                            data={ingredientGroup}
                                            groupIndex={index}
                                            key={index}
                                            onIngredientGroupChange={onIngredientGroupChange}
                                            onIngredientGroupDelete={onIngredientGroupDelete}
                                            onIngredientAdd={onIngredientAdd}
                                            setIngredientGroupPurpose={setIngredientGroupPurpose}
                                            setIsEditMode={setIsEditModeForIngredientGroup}
                                            setGroupIngredient={setGroupIngredient}
                                        />
                                    );
                                })
                            }
                        </div>
                        <button className="btn btn-primary" onClick={onAddIngredientsGroup}>Add Ingredients Group</button>
                    </div>
                    <hr />
                    <div>
                        <button className="btn btn-success float-right mb-4" onClick={createNewRecipe}>Create New Recipe</button>
                    </div>
                </form>
            </div>
        </div>);
};

export default React.memo(AddRecipePage);