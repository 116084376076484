import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons"
import Ingredient from './Ingredient'
import './IngredientsGroup.css'

const IngredientsGroup = ({
    data,
    groupIndex,
    onIngredientGroupDelete,
    onIngredientAdd,
    setIngredientGroupPurpose,
    setGroupIngredient,
    setIsEditMode
}) => {
    const onAddIngredient = (e) => {
        e.preventDefault();
        onIngredientAdd(groupIndex, '');
    };

    const onIngredientGroupTextChange = (e) => {
        e.preventDefault();
        setIngredientGroupPurpose(groupIndex, e.target.value);
    }

    const onIngredientUpdate = (ingredientIndex, ingredientName) => {
        setGroupIngredient(groupIndex, ingredientIndex, ingredientName);
    }

    const onConfirmAddGroup = (e) => {
        e.preventDefault();
        setIsEditMode(groupIndex, false);
    }

    const onIngredientGroupRemove = (e) => {
        e.preventDefault();
        var shouldDelete = window.confirm("Are you sure to delete this ingredient group?");
        if (shouldDelete) {
            onIngredientGroupDelete(groupIndex);
        } else {
            return false;
        }
    }

    return (
        <div className="IngredientsGroup col-4">
            <FontAwesomeIcon onClick={onIngredientGroupRemove} className="delete-group-icon" icon={faWindowClose} />
            <div className="form-group">
                {data.isEditMode && (
                    <>
                        <label htmlFor={`recipe-ingredient-text${groupIndex}`}>Purpose: </label>
                        <input type="text" value={data.purpose} className="form-control mb-2" id={`recipe-ingredient-group-text${groupIndex}`} placeholder="Example: To marinate chicken" onChange={onIngredientGroupTextChange} />
                        {data.items && data.items.length > 0 && (
                            <>
                                <label>Ingredients List:</label>
                                {
                                    data.items.map((item, ingredientIndex) => (<Ingredient index={ingredientIndex} key={`${groupIndex}-${ingredientIndex}`} data={item} onIngredientUpdate={onIngredientUpdate} />))
                                }
                            </>
                        )}
                        <div><span className="add-ingredient-button" onClick={onAddIngredient}><FontAwesomeIcon className="add-ingredient-icon" icon={faPlus} />Add Ingredient</span></div>
                        <div><button className="btn btn-sm btn-success mt-2" onClick={onConfirmAddGroup}>Confirm Group</button></div>
                    </>)}
                {!data.isEditMode && (
                    <div>
                        <div><label>Purpose: </label><span>{data.purpose}</span></div>
                        <div><label>Ingredients List:</label>
                            <ul className="list-group">
                                {
                                    data.items.map((item, ingredientIndex) => (<li className="list-group-item" key={`${groupIndex}-${ingredientIndex}`}>{item}</li>))
                                }
                            </ul>
                        </div>


                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(IngredientsGroup);