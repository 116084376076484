export const getNewRecipeInstance = (
    userId = '',
    datePublished = new Date(),
    ingredients = [],
    numberOfServings = '',
    pageUrl = '',
    steps = [],
    title = '',
    description = '',
    totalCookingTime = '',
    youtubeVideoId = '',
    mainImageUrl = '',
    ) => {
    const recipe = {
        ingredients,
        numberOfServings,
        datePublished,
        pageUrl,
        steps,
        title,
        description,
        totalCookingTime,
        userId,
        youtubeVideoId,
        mainImageUrl
    }
    return recipe;
}