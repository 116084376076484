import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import FileUpload from '../../components/FileUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons"
import './RecipeStep.css'

const RecipeStep = ({ data, index, onConfirmStep, onDeleteStep, onStepTextChange, onStepImageChange }) => {
    const onConfirmAddStep = (e) => {
        e.preventDefault();
        const isEditMode = false;
        onConfirmStep(index, isEditMode);
    };
    const onTextChange = (e) => {
        onStepTextChange(index, e.target.value);
    };

    const onEditStep = (e) => {
        const isEditMode = true;
        onConfirmStep(index, isEditMode);
    };

    const onDeleteExistingStep = (e) => {
        e.preventDefault();
        var shouldDelete = window.confirm("Are you sure to delete this step?");
        if (shouldDelete) {
            onDeleteStep(index);
        } else {
            return false;
        }
    }

    const onStepImageUploadComplete = (urls) => {
        const [uploadedImageUrl] = urls;
        onStepImageChange(index, uploadedImageUrl);
    };

    const onDeleteStepImage = async (e) => {
        e.preventDefault(e);
        var shouldDelete = window.confirm("Are you sure to delete this image?");
        if (shouldDelete) {
            await firebase.storage().refFromURL(data.image).delete();
            onStepImageChange(index, '');
        } else {
            return false;
        }
    };

    return (
        <div className="RecipeStep col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="form-group">
                {data.isEditMode && (
                    <div>
                        <label htmlFor={`recipe-step-text${index}`}>Text:</label>
                        <input onChange={onTextChange} type="text" className="form-control mb-2" value={data.title} id={`recipe-step-text${index}`} placeholder="Example: Put some oil and flour and mix firmly" />
                        <FileUpload
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            fileUploadPath="recipes-steps"
                            multiple
                            onFilesUploadComplete={onStepImageUploadComplete}
                            uniqId={`recipe-${index}`}
                        />
                        {data.image && (<div className="step-image-wrap"><img className="step-image" src={data.image} /><FontAwesomeIcon onClick={onDeleteStepImage} className="delete-image-icon" icon={faWindowClose} /></div>)}
                        <br />
                        <button className="btn btn-sm btn-success" onClick={onConfirmAddStep}>Confirm Step</button>
                    </div>
                )}
                {!data.isEditMode && (
                    <>
                        <hr />
                        <h6>Step {index + 1}:</h6>
                        <div>{data.title}</div>
                        <div className="step-image-wrap">{data.image && (<img className="step-image" src={data.image} />)}</div>
                        <button className="btn btn-sm btn-danger float-right" onClick={onDeleteExistingStep}>Delete Step</button>
                        <button className="btn btn-sm btn-success float-right mr-2" onClick={onEditStep}>Edit Step</button>
                        <br />
                    </>
                )}
            </div>
        </div>);
};

export default React.memo(RecipeStep);