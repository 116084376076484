import React from "react"
import { Router } from "@reach/router"
import Header from "../components/header"
import AddRecipePage from '../backend/AddRecipiePage'
import BackendDefault from '../backend/index'
import FirebaseComponent from '../components/Firebase'
import PrivateRoute from "../components/PrivateRoute"
import LoginPage from "../components/Login"

const BackendMainPage = ({ pageContext }) => {

  return (
    <>
      <FirebaseComponent>
        <Header siteTitle="Login" />
        <div className="container">
          <Router basepath="/admin">
            <LoginPage path="/login" />
            <PrivateRoute path="/add-recipe" component={AddRecipePage} />
            <BackendDefault path="/" />
          </Router>
        </div>
      </FirebaseComponent>
    </>
  )
}

BackendMainPage.propTypes = {
}

export default React.memo(BackendMainPage)
