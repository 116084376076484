import React, { useContext } from 'react';
import { navigate } from "gatsby"
import { CurrentUserContext } from '../Firebase';
import { isUserLoggedIn } from '../../utils/utils';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const userData = useContext(CurrentUserContext);
  const isTheUserLoggedIn = isUserLoggedIn(userData);
  if (!isTheUserLoggedIn) {
    navigate("/admin/login");
    return null;
  }

  if (!userData || !userData.id) {
    return null;
  }

  return <Component {...rest} userData={userData} />
};

export default React.memo(PrivateRoute);