import React, { useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { Alert } from 'reactstrap';
import { getAttributeFromFile, isValidFileType } from '../../utils/utils';

const FileUpload = (props) => {
  const {
    acceptedFileTypes, name, fileUploadPath, uniqId, error, onFilesUploadComplete, multiple,
  } = props;
  const inputFileElementRef = useRef(null);

  const saveNewFiles = async (files) => {
    const fileKeys = Object.keys(files);
    const uploadedFileUrls = [];
    if (fileKeys.length > 0) {
      for (const file of files) {
        const fileType = getAttributeFromFile('type', file);
        if (!isValidFileType(fileType, acceptedFileTypes)) {
          console.log(`Error: Upload failed. Invalid file type provided. Valid types are: ${acceptedFileTypes.toString()}`);
        } else {
          try {
            const ref = firebase.storage().ref().child(`${fileUploadPath}/${Date.now()}-${file.name}`);
            await ref.put(file);
            const fileUrl = await ref.getDownloadURL();
            uploadedFileUrls.push(fileUrl);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    onFilesUploadComplete(uploadedFileUrls);
  }

  const onFileInputChange = () => {
    const files = path(['current', 'files'])(inputFileElementRef);
    saveNewFiles(files);
  };

  return (
    <div className="FileUpload">
      <input
        ref={inputFileElementRef}
        type="file"
        id={uniqId}
        name={name}
        accept="image/png, image/jpeg"
        onChange={onFileInputChange}
        multiple={multiple}
      />
      {error && (<Alert className="mt-2" color="danger">{error}</Alert>)}
    </div>
  );
}

FileUpload.propTypes = {
  error: PropTypes.string,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  uniqId: PropTypes.string.isRequired,
  name: PropTypes.string,
  fileUploadPath: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  onFilesUploadComplete: PropTypes.func,
  multiple: PropTypes.bool,
};

FileUpload.defaultProps = {
  error: null,
  dispatch: () => {},
  onFilesUploadComplete: () => {},
  name: undefined,
  multiple: undefined,
};

export default React.memo(FileUpload);
